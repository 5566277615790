.tooltipCutter {
  font-size: 10px;
}

#planList {
  margin: 40px 0;
}

.buttonPlan {
  height: auto;
  width: 90%;
  padding: 5px;
  background-color: #faf1e6;
}

.buttonPlan:focus,
.buttonPlan:active,
.buttonPlan:hover {
  background-color: #faf1e6 !important;
  color: black !important;
  border: 1px solid #91caff !important;
}

.iconDescription {
  float: right;
  color: grey;
}

.planTitle {
  color: #3e5670;
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 0;
}

.planBedDescription {
  font-size: small;
  margin: 0;
}

.planSubtitle {
  margin-top: 0;
  min-height: 20px;
  font-size: 8px;
}

.planWatermark {
  margin: -45px;
  opacity: 0.25;
}

.planWatermark img {
  height: 100px;
}

.planPriceUnit {
  color: #3e5670;
  font-size: 10px;
  margin-bottom: -8px;
}

.planCost {
  color: #3e5670;
  font-size: 21px;
  margin: 0;
  font-weight: 800;
  /* font-weight: bold; */
}

.planPeriodUnit {
  color: #3e5670;
  margin-top: -4px;
  font-size: 10px;
}

.planLimit {
  margin-bottom: 14px;
}

.planLimitTitle {
  margin-bottom: 0px;
  font-size: 10px;
}

.planLimitCoverage {
  font-size: 26px;
}

.planLimitBoosterDesc {
  color: #01a151;
  float: inline-end;
  font-weight: 500;
  font-size: 8px;
  float: inline-end;
}

.planLimitBooster {
  color: #01a151;
  vertical-align: top;
  font-weight: 500;
}
