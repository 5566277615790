#logoDeva {
  height: 50px;
  margin: 10px;
}

#logoMSIG {
  height: 60px;
  float: right;
  margin: 10px;
}

#header {
  background-color: #f5f5f5;
  color: #3c546e;
  height: 70px;
  position: 'sticky';
  top: 0;
  z-index: 1;
  width: '100%';
  display: 'flex';
  align-items: 'center';
  margin-top: 20px;
}

#content {
  padding: 20px 40px;
}

#footer {
  text-align: center;
}
