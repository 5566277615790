.buttonGender {
  height: 120px;
  margin: 0 10px;
}

.buttonGender:hover,
.buttonGender:active,
.buttonGender:focus {
  border: 1px solid #91caff !important;
}

.iconGender {
  height: 100px;
}

#buttonCount {
  background-color: #3c546e;
  color: white;
}
